import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductMain from "../components/product-main"
import HomeContact from "../components/home-contact"
import ProductIcons from "../components/product-icons"
import ProductPortion from "../components/product-portion"
import ProductInfo from "../components/product-info"
import ProductRelation from "../components/product-raletion"

const Page = ({ data }) => {
  const currentPost = data.currentPost
  const relatedPosts = data.relatedPosts.nodes

  return (
    <Layout>
      <SEO post={currentPost} />
      <ProductMain post={currentPost} />
      <ProductIcons post={currentPost} />
      <ProductPortion post={currentPost} />
      <ProductInfo post={currentPost} />
      <ProductRelation posts={relatedPosts} />
      <HomeContact />
    </Layout >
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!, $primary_categoria_produtos_id: Int) {
    currentPost: wordpressProduto(id: {eq: $id }) {
      ...ProdutoFull
    }

    relatedPosts: allWordpressProduto(
      limit: 10,
      filter: {id: {nin: [$id]}, primary_categoria_produtos_id: {eq: $primary_categoria_produtos_id}, status: {eq: "publish"}},
      sort: {order: ASC, fields: title}
    ) {
      nodes {
        ...ProdutoItem
      }
    }
  }
`
