import React from "react"
import { get } from "lodash"

const ProductPortion = ({ post }) => {
  const ingredients_text = get(post, "acf.ingredients_text")
  const ingredients_attention = get(post, "acf.ingredients_attention")

  if (!ingredients_text)
    return null

  return (
    <section className="product-portion-component has-background-secondary is-padding-vertical-8">
      <div className="container has-text-centered">
        <div className="columns is-gapless is-centered">
          <div className="column is-half">
            <h3 className="is-size-1 has-text-weight-bold is-margin-bottom-8">
              Ingredientes
            </h3>
            <div className="is-size-6 is-margin-bottom-8">
              {ingredients_text}
            </div>
            <div className="is-size-6 has-text-weight-bold is-margin-bottom-6">
              ATENÇÃO
            </div>
            <div className="is-size-6 has-text-weight-bold">
              {ingredients_attention}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductPortion
