import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Thumbnail from "../domain/thumbnail"
import Router from "../domain/router"

const ProductMain = ({ post }) => {
  const imageSrc = Thumbnail.productSingleImage(post)

  return (
    <section className="product-main-component is-margin-bottom-14">
      <div className="columns is-gapless is-multiline">
        <div className="column is-12-touch">
          <div className="columns is-mobile is-gapless">
            <div className="column is-narrow">
              <div className="image-tab one">
                <LazyLoadImage src="/images/product-photo-1.jpg" alt="Aba" />
              </div>
            </div>
            <div className="column">
              <div className="box-photo">
                {imageSrc && <LazyLoadImage src={imageSrc} alt={post.title} />}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-12-touch">
          <div className="columns is-mobile is-gapless is-full">
            <div className="column is-narrow">
              <div className="image-tab two is-hidden-mobile">
                <LazyLoadImage src="/images/product-photo-2.jpg" alt="Aba" />
              </div>
            </div>
            <div className="column">

              <div className="box-info is-padding-vertical-8 is-padding-left-8 is-padding-vertical-8-touch is-padding-horizontal-4-touch has-text-white">
                <div className="box-info-inner">

                  {/* <div className="columns is-vcentered is-gapless">
                  <div className="column is-narrow">
                    <div className="is-padding-right-3">
                    </div>
                  </div>
                  <div className="column is-narrow has-text-weight-bold">
                    Sucos
                  </div>
                </div> */}

                  <div className="is-margin-bottom-8">
                    <h1 className="has-text-weight-bold is-size-1 is-size-2-touch">
                      {post.title}
                    </h1>
                  </div>

                  <div className="is-margin-bottom-10">
                    <div className="content content-white content-20" dangerouslySetInnerHTML={{ __html: post.content }} />
                  </div>

                  {post.acf && post.acf.flavor && post.acf.flavor_relation &&
                    <div className="is-margin-bottom-10">
                      <div className="has-text-weight-bold is-margin-bottom-2">
                        OUTROS SABORES
                    </div>

                      <div className="columns is-multiline">
                        <div className="column is-narrow">
                          <span className="has-text-secondary has-text-weight-bold is-size-6 is-underscore">
                            {post.acf && post.acf.flavor}
                          </span>
                        </div>
                        {post.acf && post.acf.flavor_relation &&
                          post.acf.flavor_relation.map(relation => (
                            <div key={relation.id} className="column is-narrow">
                              <Link to={Router.productItemPath(relation)} className="has-text-white has-text-weight-bold is-size-6">
                                {relation.acf && relation.acf.flavor}
                              </Link>
                            </div>))}
                      </div>
                    </div>}

                  {post.acf && post.acf.where_buy &&
                    <div>
                      <a href={post.acf.where_buy} className="button is-rounded has-text-orange">
                        <strong>Onde Comprar</strong>
                      </a>
                    </div>}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductMain
