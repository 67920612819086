import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ProductIcons = ({ post }) => {
  const stamps = post.stamp

  return (
    <section className="product-icons-component is-padding-horizontal-3 is-margin-bottom-8">
      <div className="container">
        <div className="columns" style={{ maxWidth: 1200, margin: '0 auto' }}>
          <div className="column">
            {stamps[0] && <IconItem stamp={stamps[0]} />}
          </div>
          <div className="column">
            {stamps[1] && <IconItem stamp={stamps[1]} />}
          </div>
          <div className="column">
            {stamps[2] && <IconItem stamp={stamps[2]} />}
          </div>
        </div>
      </div>
    </section>
  )
}

const IconItem = ({ stamp }) => (
  <div className="icon-item has-text-centered is-margin-bottom-8-touch" style={{ maxWidth: '250px', margin: '0 auto' }}>
    <div className="is-margin-bottom-6">
      {stamp.acf && stamp.acf.icon &&
        <LazyLoadImage src={stamp.acf.icon.url} alt={stamp.name} />}
    </div>
    <div className="is-margin-bottom-2">
      <h3 className="is-size-3 has-text-weight-bold">
        {stamp.name}
      </h3>
    </div>
    {/* <div>
      <p className="is-size-5">
        Suco 100% fruta, feito especialmente para quem deseja ter uma vida mais saudável.
      </p>
    </div> */}
  </div>
)

export default ProductIcons
