import React from "react"
import { Link } from "gatsby"
import ProductSlide from "./product-slide"
import Router from "../domain/router"

const ProductRelation = ({ posts }) => {
  if (!posts || posts.length === 0) return null

  return (
    <section className="product-relation-component is-margin-bottom-12">
      <div className="container">
        <div className="has-text-centered is-margin-bottom-8">
          <h2 className="title is-1">
            Produtos Relacionados
          </h2>
        </div>
        <div className="is-margin-bottom-6">
          <ProductSlide posts={posts} />
        </div>
        <div className="is-margin-bottom-10 has-text-centered">
          <Link to={Router.productsPath()} className="button is-primary is-inverted is-uppercase has-text-weight-bold">
            Ver todos os produtos
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ProductRelation
