import React from "react"

const ProductInfo = ({ post }) => {
  if (!post.acf || !post.acf.table)
    return null

  const { portion } = post.acf

  return (
    <section className="product-info-component is-padding-vertical-9 is-margin-bottom-12">
      <div className="container">
        <div className="columns is-centered is-gapless">
          <div className="column is-9">

            <div className="is-margin-bottom-10">
              <div className="table-container">
                <table className="table is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th>Quantidade por porção {portion}</th>
                      <th></th>
                      <th className="has-text-centered">%VD(*)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {post.acf.table.map((table, index) => (
                      <tr key={index}>
                        <th>{table.name}</th>
                        <td>{table.weight}</td>
                        <td>{table.percentage}</td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="is-padding-horizontal-4-touch text-bottom">
              <div className="has-text-centered is-margin-bottom-6">
                <p className="has-text-weight-bold is-font-size-13">
                  (*) % Valores Diários de Referência com base em uma dieta de 2.000 kcal ou 8400 kJ. Seus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.
                </p>
              </div>

              <div className="has-text-centered">
                <p className="has-text-weight-bold is-font-size-13">
                  (**) Valores Diários de Referência não estabelecidos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductInfo
